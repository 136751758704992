import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastProvider } from "./context/ToastContext";
import { WindowWidthProvider } from "./context/WindowWidthContext";
import { GlobalStateProvider } from "./context/GlobalContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GlobalStateProvider>
    <WindowWidthProvider>
      <ToastProvider>
        <App />
      </ToastProvider>
    </WindowWidthProvider>
  </GlobalStateProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
