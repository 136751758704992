import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "../../../../context/ToastContext";
import httpService from "../../../../utils/request";
import { RotatingLines } from "react-loader-spinner";

const languageSchema = z.object({
  title: z.string().optional().nullable(),
  type: z.string().min(1, "Loại Popup là bắt buộc!"),
  isActive: z.boolean(),
  image: z
    .instanceof(FileList) // Check if it is a FileList
    .refine((fileList) => fileList.length > 0, {
      message: "Ảnh là bắt buộc",
    }),
});

const OPTIONS = [
  {
    id: "POPUP_REGISTER",
    name: "Ảnh Popup đăng ký",
  },
  {
    id: "POPUP_PAYMENT",
    name: "Ảnh Popup thanh toán",
  },
  {
    id: "POPUP_JUST_PAYMENT",
    name: "Ảnh Popup nhắc đã thanh toán",
  },
];

const CreateLanguageModal = ({ show, handleClose, handleCreate }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      title: "",
      isActive: true,
    },
    resolver: zodResolver(languageSchema),
  });
  const showToast = useToast();

  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const imageFile = watch("image");

  const isActive = watch("isActive");

  useEffect(() => {
    if (imageFile && imageFile.length > 0) {
      const file = imageFile[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setImagePreview(null);
    }
  }, [imageFile]);

  const onSubmit = async (data) => {
    try {
      try {
        setLoading(true);
        const dataImage = await httpService.post("/private/upload", {
          image: imagePreview,
        });
        const dataResquest = {
          ...data,
          image: dataImage,
        };

        const response = await httpService.post(
          "/private/banners",
          dataResquest
        );
        setLoading(false);
        handleCreate({ id: response.id, ...dataResquest });
        showToast("Thêm mói ảnh popup thành công", { type: "success" });
        reset();
        setImagePreview(null);
      } catch (error) {
        showToast(error.response.data, {
          position: "top-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      showToast("Có lỗi xảy ra", { type: "error" });
    }
  };

  const handleCloseModal = () => {
    reset();
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>Thêm mới ảnh popup</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            position: "relative",
          }}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="languageName">
              <Form.Label>Mô tả</Form.Label>
              <Form.Control
                type="text"
                {...register("title")}
                isInvalid={!!errors.title}
              />
              {errors.title && (
                <Form.Control.Feedback type="invalid">
                  {errors.title.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="type">
              <Form.Label>Loại Popup</Form.Label>
              <Form.Control
                as="select"
                {...register("type")}
                isInvalid={!!errors.type}
              >
                <option value="">Chọn loại popup</option>
                {(OPTIONS || []).map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
              {errors.type && (
                <Form.Control.Feedback type="invalid">
                  {errors.type.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="courseCategoryImage">
              <Form.Label>
                Ảnh <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                isInvalid={!!errors.image}
                type="file"
                accept="image/*"
                {...register("image", { required: "File is required" })}
              />
              {imagePreview && (
                <div className="mt-3">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      width: "160px",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
              {errors.image && (
                <Form.Control.Feedback type="invalid">
                  {errors.image.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group controlId="isActive" className="mt-2 d-flex gap-2">
              <Form.Label>Trạng thái active</Form.Label>
              <Form.Check
                type="switch"
                {...register("isActive")}
                checked={!!isActive}
                onChange={(e) => setValue("isActive", e.target.checked)}
              />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Đóng
              </Button>
              <Button variant="primary" type="submit">
                Thêm mới
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
        {loading && (
          <div style={{ position: "absolute", top: "30%", left: "40%" }}>
            <RotatingLines color="green" radius={"8px"} />
          </div>
        )}
      </Modal>
    </>
  );
};

export default CreateLanguageModal;
