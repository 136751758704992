import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import MyNavbar from "./components/Header";
import Home from "./pages/Home";
import Payment from "./pages/Payment";
import GreatCategory from "./pages/User/GreatCategory";
import ListeningCategory from "./pages/User/ListeningCategory";
import UpdateCategory from "./pages/User/UpdateCategory";
import Course from "./pages/User/Course";
import CourseOfCategory from "./pages/User/CourseOfCategory";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Admin from "./pages/Admin/Admin";
import { isAdmin, isLogin, getUserEmail, isLoginAndPayment } from "./utils";
import "./App.css";
import { FaTimes } from "react-icons/fa";
import { useGlobalState } from "./context/GlobalContext";
import { useWindowWidth } from "./context/WindowWidthContext";
import { DOMAIN } from "./constants";
import { useNavigate } from "react-router-dom";
import { isMobile as isMobile1 } from "react-device-detect";
import httpService from "./utils/request";
import { useToast } from "./context/ToastContext";

const ProtectedRoute = ({ element }) => {
  return isLogin() ? element : <Navigate to="/login" />;
};

const closeIconStyles = {
  position: "absolute",
  top: "-10px",
  right: "-5px",
  width: "30px",
  height: "30px",
  backgroundColor: "#fff",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "18px",
  cursor: "pointer",
  color: "#333",
  border: "1px solid #ccc",
  zIndex: "1050",
  transition: "background-color 0.3s ease, color 0.3s ease",
};

const PublicRouter = ({ element }) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowWidth();

  const [showPopup, setShowPopup] = useState(false);
  const [showPopupJustPayment, setShowPopupJustPayment] = useState(false);
  const { state } = useGlobalState();
  const popupData = state.popupData.find((x) => x.type === "POPUP_REGISTER");
  const popupPaymentedData = state.popupData.find(
    (x) => x.type === "POPUP_JUST_PAYMENT"
  );

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleClosePopupJustPayment = () => {
    setShowPopupJustPayment(false);
  };

  useEffect(() => {
    const isLoggedIn = isLogin();
    const popupKey = "hasSeenPopup";
    const popupPaymentedKey = "hasSeenPopupPaymented";

    const popupExpirationKey = "popupExpirationTime";
    const popupExpirationPaymentedKey = "popupExpirationPaymentedTime";

    const expirationDuration = 24 * 60 * 60 * 1000;
    const expirationPaymentedDuration = 24 * 60 * 60 * 1000;

    const currentTime = new Date().getTime();
    const expirationTime = localStorage.getItem(popupExpirationKey);
    const expirationPaymentedTime = localStorage.getItem(
      popupExpirationPaymentedKey
    );

    if (expirationTime && currentTime > expirationTime) {
      localStorage.removeItem(popupKey);
      localStorage.removeItem(popupExpirationKey);
    }

    if (expirationPaymentedTime && currentTime > expirationPaymentedTime) {
      localStorage.removeItem(popupPaymentedKey);
      localStorage.removeItem(popupExpirationPaymentedKey);
      localStorage.removeItem("userSeen");
    }

    if (expirationPaymentedTime && isLoggedIn) {
      const username = getUserEmail();
      const currentSeen = localStorage.getItem("userSeen");
      if (username !== currentSeen) {
        localStorage.removeItem(popupPaymentedKey);
        localStorage.removeItem(popupExpirationPaymentedKey);
        localStorage.removeItem("userSeen");
      }
    }

    const hasSeenPopup = localStorage.getItem("hasSeenPopup");
    const hasSeenPaymentPopup = localStorage.getItem(popupPaymentedKey);

    if (!isLoggedIn && !hasSeenPopup) {
      setShowPopup(true);
      localStorage.setItem(popupKey, "true");

      localStorage.setItem(
        popupExpirationKey,
        currentTime + expirationDuration
      );
    }

    if (isLoginAndPayment() && !hasSeenPaymentPopup) {
      const username = getUserEmail();
      setShowPopupJustPayment(true);
      localStorage.setItem(popupPaymentedKey, "true");

      localStorage.setItem(
        popupExpirationPaymentedKey,
        currentTime + expirationPaymentedDuration
      );
      localStorage.setItem("userSeen", username);
    }
  }, []);

  return (
    <div className="root-content">
      <div className="root-content-header">
        <MyNavbar />
      </div>
      <div className="root-content-body">{element}</div>
      {showPopup && popupData && (
        <Modal
          className="custom-modal"
          show={showPopup}
          onHide={handleClosePopup}
          centered
          style={{
            width: "100%",
          }}
        >
          <Modal.Body
            style={{
              position: "relative",
              padding: 0,
            }}
          >
            <span style={closeIconStyles} onClick={handleClosePopup}>
              <FaTimes />
            </span>
            <img
              style={{ height: "auto", objectFit: "cover" }}
              className="d-block w-100"
              src={`${DOMAIN}${popupData.image}`}
              alt=""
            />
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#58d062" }}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "600",
              }}
            >
              <Button
                className="custom-btn"
                style={{
                  fontSize: "18px",
                  background: "linear-gradient(to right, #ff7f00, #ff9800)",
                  width: "80%",
                  color: "#000",
                  padding: "10px",
                  borderRadius: "50px",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  border: "unset",
                  transition: "background 0.3s ease",
                }}
                onClick={() => {
                  setShowPopup(false);
                  navigate("/sign-up");
                }}
              >
                Đăng ký ngay
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
      {showPopupJustPayment && popupPaymentedData && (
        <Modal
          className="custom-modal"
          show={showPopupJustPayment}
          onHide={handleClosePopupJustPayment}
          centered
          style={{
            width: "100%",
          }}
        >
          <Modal.Body
            style={{
              position: "relative",
              padding: 0,
            }}
          >
            <span style={closeIconStyles} onClick={handleClosePopupJustPayment}>
              <FaTimes />
            </span>
            <img
              style={{ height: "auto", objectFit: "cover" }}
              className="d-block w-100"
              src={`${DOMAIN}${popupPaymentedData.image}`}
              alt=""
            />
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#58d062" }}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: "600",
              }}
            >
              <Button
                className="custom-btn"
                style={{
                  fontSize: "18px",
                  background: "linear-gradient(to right, #ff7f00, #ff9800)",
                  width: "80%",
                  color: "#000",
                  padding: "10px",
                  borderRadius: "50px",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  border: "unset",
                  transition: "background 0.3s ease",
                }}
                onClick={() => {
                  setShowPopupJustPayment(false);
                }}
              >
                Đóng
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

const App = () => {
  const showToast = useToast();

  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      const token = localStorage.getItem("token");

      if (token && !isAdmin()) {
        try {
          const response = await httpService.post(`/auth/check-login`, {
            username: getUserEmail(),
            deviceType: isMobile1 ? "mobile" : "web",
            tokenLogin: token,
          });
          if (response && response.needLogout) {
            window.location.href = "/";
            localStorage.removeItem("token");
            showToast("Bạn đang đăng nhập ở nơi khác.", { type: "error" });
          }
          if (response && response.isLock) {
            window.location.href = "/";
            localStorage.removeItem("token");
            showToast("Quá số thiết bị đăng nhập. Tài khoản đã bị khóa.", {
              type: "error",
            });
          }
        } catch (error) {}
      }
    };
    handleBeforeUnload();
    const intervalId = setInterval(() => {
      handleBeforeUnload();
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={isAdmin() ? "admin-content" : "root-content"}>
      <Router>
        <div style={{ width: "100%", flex: 1 }}>
          <Routes>
            <Route
              path="/"
              exact
              element={<PublicRouter element={<Home />} />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route
              path="/great-category"
              element={<PublicRouter element={<GreatCategory />} />}
            />
            <Route
              path="/kaiwa-category"
              element={
                <PublicRouter element={<ListeningCategory type={"kaiwa"} />} />
              }
            />
            <Route
              path="/jlpt-category"
              element={
                <PublicRouter element={<ListeningCategory type={"jlpt"} />} />
              }
            />
            <Route
              path="/listening-category"
              element={
                <PublicRouter
                  element={<ListeningCategory type={"listening"} />}
                />
              }
            />
            <Route
              path="/update-category"
              element={<PublicRouter element={<UpdateCategory />} />}
            />
            <Route
              path="/payment"
              element={<PublicRouter element={<Payment />} />}
            />
            <Route
              path="/category/:id"
              element={<PublicRouter element={<Course />} />}
            />
            <Route
              path="/sub-category/:id"
              element={<PublicRouter element={<CourseOfCategory />} />}
            />
            <Route
              path="/quanly/*"
              element={<ProtectedRoute element={<Admin />} />}
            />
          </Routes>
        </div>
      </Router>
    </div>
  );
};

export default App;
