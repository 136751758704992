import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "../components/styles/login.css";
import httpService from "../utils/request";
import { useNavigate } from "react-router-dom";
import { isAdmin } from "../utils";
import { useToast } from "../context/ToastContext";
import { isMobile } from "react-device-detect";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const showToast = useToast();

  const validateForm = () => {
    const newErrors = {};
    if (!email) newErrors.email = "Email is required";
    if (!password) newErrors.password = "Password is required";
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      try {
        const response = await httpService.post(`/auth/login`, {
          username: email,
          password,
          deviceType: isMobile ? "mobile" : "web",
        });
        localStorage.setItem("token", response.token);
        if (isAdmin()) {
          navigate("/quanly");
        } else {
          navigate("/");
        }
      } catch (error) {
        showToast(error.response.data, {
          position: "top-right",
          autoClose: 3000,
          type: "error",
        });
      }
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-form-container">
        <h2 className="login-title">Học Tiếng Nhật Cùng chúng tôi</h2>
        <div className="login-title-sub">
          <p className="no-margin">Tiếng Nhật khó, đã có chúng tôi!</p>
          <p className="no-margin">
            Hệ sinh thái Nhật ngữ số 1 dành cho người Việt!
          </p>
        </div>
        <Form onSubmit={handleSubmit} className="login-form">
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Tài Khoản</Form.Label>
            <Form.Control
              style={{ border: "1px solid #57d061" }}
              placeholder="Tài Khoản"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Mật Khẩu</Form.Label>
            <Form.Control
              style={{ border: "1px solid #57d061" }}
              type="password"
              placeholder="Mật Khẩu"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Button variant="success" type="submit" className="login-button">
            Đăng Nhập
          </Button>
          <div style={{ marginTop: "10px", textAlign: "center" }}>
            Bạn chưa có tài khoản?{" "}
            <span
              onClick={() => {
                navigate("/sign-up");
              }}
              style={{ color: "blue", cursor: "pointer" }}
            >
              Đăng ký ngay
            </span>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Login;
