import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import "./styles/header.css";
import { isLogin, getUserEmail } from "../utils";
import { useNavigate } from "react-router-dom";
import { useWindowWidth } from "../context/WindowWidthContext";
import { useGlobalState } from "../context/GlobalContext";
import { isMobile as isMobile1 } from "react-device-detect";
import httpService from "../utils/request";
import { useToast } from "../context/ToastContext";

function OffcanvasExample() {
  const navigate = useNavigate();
  const { isMobile } = useWindowWidth();
  const { state } = useGlobalState();
  const showToast = useToast();
  const support = state.supportData;
  return (
    <>
      {["sm"].map((expand) => (
        <Navbar key={expand} expand={expand}>
          <Container>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Body
                className={
                  isMobile ? "mobile-nav offcanvas-body" : "offcanvas-body"
                }
              >
                <Nav
                  className="flex-grow-1"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    className="custom-nav leftNav"
                    style={{
                      display: "flex",
                      fontSize: !isMobile ? "16px" : "14px",
                    }}
                  >
                    <Nav.Link as={Link} to="/">
                      Các Khóa Học
                    </Nav.Link>
                    <Nav.Link as={Link} to="/jlpt-category">
                      JLPT
                    </Nav.Link>
                    <Nav.Link as={Link} to="/kaiwa-category">
                      Kaiwa
                    </Nav.Link>
                    <Nav.Link as={Link} to="/listening-category">
                      Luyện Thi
                    </Nav.Link>
                    <Nav.Link as={Link} to="/great-category">
                      Khóa học hay
                    </Nav.Link>
                    <Nav.Link
                      href={support.paymentUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Hướng dẫn thanh toán
                    </Nav.Link>
                    {/* <Nav.Link as={Link} to="/update-category">
                      Khóa học update
                    </Nav.Link> */}
                  </div>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <div
              className="custom-nav"
              style={{ display: "flex", fontSize: !isMobile ? "16px" : "14px" }}
            >
              {!isLogin() && (
                <div className="wrap_login">
                  <Nav.Link as={Link} to="/login" style={{ marginRight: 0 }}>
                    Đăng nhập /
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/sign-up"
                    style={{ paddingLeft: "4px" }}
                  >
                    Đăng ký
                  </Nav.Link>
                </div>
              )}
              {isLogin() && (
                <div className="wrap_login">
                  <Nav.Link
                    onClick={async () => {
                      try {
                        const response = await httpService.post(
                          `/auth/logout`,
                          {
                            username: getUserEmail(),
                            deviceType: isMobile1 ? "mobile" : "web",
                          }
                        );
                        localStorage.removeItem("token");
                        navigate("/");
                      } catch (error) {
                        showToast(error.response.data, {
                          position: "top-right",
                          autoClose: 3000,
                          type: "error",
                        });
                      }
                    }}
                  >
                    Đăng xuất
                  </Nav.Link>
                </div>
              )}
            </div>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default OffcanvasExample;
